import React from "react"
import Image from "./Image"
import PropTypes from "prop-types"
export default function Hero(props) {
  return (
    <div className="container mx-auto pt-4 pb-4 md:pb-0 px-2 md:px-0">
      <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
        <div>
          <div className="max-w-sm sm:max-w-md md:max-w-lg mx-auto">
            <Image
              className="hidden lg:block"
              src={props.image}
              alt={props.altText}
            />
            <Image
              className="block xs:block sm:block md:block lg:hidden"
              src={props.image2}
              alt={props.altText}
            />
          </div>
        </div>
        <div className="items-center justify-center flex">
          <div>
            <div className="heroTextWrapper lg:mt-32 ml-0 md:ml-8 xl:mt-0">
              <h1
                className="titleUnderline mb-3 white"
                dangerouslySetInnerHTML={{ __html: props.heroTitle }}
              ></h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div>${props.heroBody}</div>`
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  props: PropTypes.object,
  altText: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heroTitle: PropTypes.string,
  heroBody: PropTypes.string
}
