import React from "react"
import PropTypes from "prop-types"

export default function Quote(props) {
  return (
    <div className="">
      <div className="quote inline-block align-middle">
        <h2 className="text-coupa-blue text-center">{props.quoteText}</h2>
        <h3 className="text-coupa-blue text-center">-{props.quoteAuthor}</h3>
      </div>
    </div>
  )
}

Quote.propTypes = {
  props: PropTypes.object,
  quoteText: PropTypes.string,
  quoteAuthor: PropTypes.string,
}
