import React from "react"
import TweetSection from "../components/TweetSection"
//Hero Section
import Hero from "../components/homeHero"
//Quote Sections
import QuoteContainer from "../components/QuoteComponents/quoteContainer"
//CTA Sections
import {
  CtaContainerImageLeft,
  CtaContainerImageRight
} from "../components/CtaComponents/ctaContainer" //Cards
import LogoGrid from "../components/picureGrid"
import ArticleCardContainer from "../components/ArticleCards/CardContainer"

function IndexPageComponent(props) {
  let yaml = props.textYaml
  return (
    <>
      <div className="hero">
        <Hero
          image={props.robBG}
          heroTitle={yaml.heroTitle}
          heroBody={yaml.heroBody}
          image2={props.robMobile}
        />
      </div>
      <QuoteContainer
        quoteText={yaml.quoteText}
        quoteAuthor={yaml.quoteAuthor}
        image={props.robQuote1}
      />
      <div className="on-homepage">
        <ArticleCardContainer cardData={props.allMarkdownRemark.edges} />
      </div>

      <CtaContainerImageRight
        ctaTitle={yaml.ctaTitle}
        ctaSubTitle={yaml.ctaSubTitle}
        ctaButton={yaml.ctaButton}
        ctaCopy={yaml.ctaCopy}
        ctaImage={props.cta1}
        to="https://www.amazon.com/Smarter-Together-Communities-Revolution-Business/dp/1626347069"
        className="max-w-xs mx-auto my-0"
        id="smarterTogether"
        cards={yaml.cardText}
      />
      <CtaContainerImageLeft
        ctaTitle={yaml.ctaTitle1}
        ctaSubTitle={yaml.ctaSubTitle1}
        right
        ctaButton={yaml.ctaButton1}
        ctaCopy={yaml.ctaCopy1}
        to="https://www.amazon.com/Value-Service-Embracing-Coming-Disruption/dp/1626343055"
        ctaImage={props.cta2}
        className="max-w-xs mx-auto my-0"
        id="valueAsAService"
        cards={yaml.cardText2}
      />
      <QuoteContainer
        quoteText={yaml.quoteText1}
        quoteAuthor={yaml.quoteAuthor1}
        right
        image={props.robQuote2}
      />
      <div className="coupa-section bottom-border">
        <a className="anchor" href="/" id="perspectives">
          {null}
        </a>
        <div className="container mx-auto">
          <h2 className="text-center">{yaml.logoGridHeading}</h2>
          <LogoGrid gridItems={yaml.logoGrid} />
        </div>
      </div>
      <a className="anchor" id="aboutRob" href="/">
        {null}
      </a>
      <div className="tweetBG">
        <TweetSection
          placeholder={yaml.tweetPlaceholder}
          twitterProfile={props.twitterProfile}
          button={yaml.tweetButton}
          ato="/gallery"
          button1={yaml.tweetButton1}
          Linkto="/gallery"
          tweetTitle={yaml.tweetTitle}
          tweetSubTitle={yaml.tweetSubTitle}
          tweetCopy={yaml.tweetCopy}
        />
      </div>
    </>
  )
}

export default IndexPageComponent
