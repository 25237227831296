import React from "react"
import PropTypes from "prop-types"

export default function Card(props) {
  return (
    <div className="card relative sm:mb-4 xs:mb-4">
      <meta charSet="utf8" />
      <div
        dangerouslySetInnerHTML={{ __html: props.cardQuote }}
        className="cardQuote text-left mb-4"
      />
      <div className="cardAuthor mb-0">
        <span
          dangerouslySetInnerHTML={{ __html: props.cardAuthorTitle }}
        ></span>
      </div>
    </div>
  )
}

Card.propTypes = {
  props: PropTypes.object,
  cardAuthor: PropTypes.string,
  cardAuthorTitle: PropTypes.string,
  cardQuote: PropTypes.string,
}
