import React from "react"
import PropTypes from "prop-types"
import Quote from "./quote"
import Image from "../Image"

const quoteContainer = props => (
  <div className="background-alt bottom-border px-2 md:px-0">
    <div className="container mx-auto pt-4 pb-0 md:pb-0">
      <div className="">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
          {props.right ? (
            <div className="col-span-1 relative order-2 md:order-1">
              <div className="max-w-13 md:max-w-none mx-auto">
                <Image
                  className="md:absolute sm:max-w-9/12 bottom-0 right-0 my-0 mx-auto"
                  src={props.image}
                />
              </div>
            </div>
          ) : (
            <div className="col-span-2 flex items-center order-1 md:order-2">
              <div className="">
                <Quote
                  quoteText={props.quoteText}
                  quoteAuthor={props.quoteAuthor}
                />
              </div>
            </div>
          )}
          {props.right ? (
            <div className="col-span-2 order-1">
              <div className="pt-16 pb-16">
                <Quote
                  quoteText={props.quoteText}
                  quoteAuthor={props.quoteAuthor}
                />
              </div>
            </div>
          ) : (
            <div className="col-span-1 relative order-2">
              <div className="max-w-13 md:max-w-none mx-auto ">
                <Image
                  className="md:absolute sm:max-w-9/12 bottom-0 right-0 my-0 mx-auto"
                  src={props.image}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)

quoteContainer.propTypes = {
  props: PropTypes.object,
  right: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  quoteText: PropTypes.string,
  quoteAuthor: PropTypes.string
}

export default quoteContainer
