import React from "react"
import PropTypes from "prop-types"

export default function CtaA(props) {
  return (
    <div className="">
      <h2
        className="titleUnderline mb-3"
        dangerouslySetInnerHTML={{ __html: props.ctaTitle }}
      ></h2>
      <h3 className="cta-subhead text-base">{props.ctaSubTitle}</h3>
      <div
        dangerouslySetInnerHTML={{ __html: props.ctaCopy }}
        className="text-coupa-grey"
      ></div>
    </div>
  )
}

CtaA.propTypes = {
  props: PropTypes.object,
  ctaTitle: PropTypes.string,
  ctaCopy: PropTypes.string,
  ctaSubTitle: PropTypes.string
}
