import React from "react"
import PropTypes from "prop-types"
import Cta from "./CtaComponents/cta"
import { Link } from "gatsby"

const TweetSection = props => {
  return (
    <div className="coupa-section bottom-border tweetSection px-4 md:px-0">
      <div className="container mx-auto">
        <div className="">
          <div className="container mx-auto mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid cols-2 gap-16">
              <div className="order-2 md:order-1">
                <a
                  className="twitter-timeline"
                  data-lang="en"
                  data-width="450"
                  data-height="500"
                  href="https://twitter.com/rbernshteyn?ref_src=twsrc%5Etfw"
                >
                  {props.placeholder}
                </a>
              </div>
              <div className="order-1 md:order-2">
                <Cta
                  ctaTitle={props.tweetTitle}
                  lineColor="text-coupa-blue"
                  ctaCopy={props.tweetCopy}
                />
                <div className="nline text-center md:text-left">
                  <Link
                    className="bg-coupa-blue coupa-button hover:bg-coupa-blue-light my-2"
                    to={props.ato}
                  >
                    {props.button}
                  </Link>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TweetSection.propTypes = {
  props: PropTypes.object,
  placeholder: PropTypes.string,
  tweetTitle: PropTypes.string,
  tweetSubTitle: PropTypes.string,
  tweetCopy: PropTypes.string,
  subheading: PropTypes.string,
  ato: PropTypes.string,
  button: PropTypes.string,
  Linkto: PropTypes.string,
  button1: PropTypes.string,
}

export default TweetSection
