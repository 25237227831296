import React from "react"
import Cta from "./cta"
import Card from "../card"
import Image from "../Image"

export const CtaContainerImageRight = ({
  cards,
  ctaTitle,
  ctaSubTitle,
  id,
  to,
  ctaCopy,
  ctaImage,
  ctaAlt,
  className,
  ctaButton,
}) => (
  <div className="px-4 coupa-section cta bottom-border md:px-0">
    <a className="anchor" href="./" id={id}>
      {null}
    </a>
    <div className="container mx-auto">
      <div className="">
        <div className="container pb-8 mx-auto mb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid cols-2 gap-4">
            <div className="order-2 pt-6 md:order-1">
              <div className="md:px-4 lg:pl-10 xl:pl-20">
                <Cta
                  ctaTitle={ctaTitle}
                  lineColor="text-coupa-blue"
                  ctaSubTitle={ctaSubTitle}
                  ctaCopy={ctaCopy}
                />
                <a
                  className="bg-coupa-blue coupa-button hover:bg-coupa-blue-light"
                  href={to}
                  target="_blank"
                  rel="noreferrer"
                >
                  {ctaButton}
                </a>
              </div>
            </div>
            <div className="order-1 md:order-2">
              <div className="mx-auto max-w-19">
                <Image src={ctaImage} alt={ctaAlt} className={className} />
              </div>
            </div>
          </div>
        </div>
        <div className="container mx-auto text-center">
          <div className="flex flex-wrap">
            {cards.map(card => (
              <div
                className="flex w-full px-8 mb-4 md:w-1/3 md:px-4 md:mb-0"
                key={card.cardQuote}
              >
                <Card
                  cardQuote={card.cardQuote}
                  cardAuthorTitle={card.cardAuthorTitle}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const CtaContainerImageLeft = ({
  cards,
  ctaTitle,
  ctaSubTitle,
  id,
  to,
  ctaCopy,
  ctaImage,
  ctaAlt,
  className,
  ctaButton,
}) => (
  <div className="px-4 coupa-section cta bottom-border md:px-0">
    <a href="./" className="anchor" id={id}>
      {null}
    </a>
    <div className="container mx-auto">
      <div className="">
        <div className="container pb-8 mx-auto mb-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid cols-2 gap-4">
            <div>
              <div className="mx-auto max-w-15">
                <Image src={ctaImage} alt={ctaAlt} className={className} />
              </div>
            </div>
            <div className="pt-6 lg:pr-10 xl:pr-20">
              <Cta
                ctaTitle={ctaTitle}
                lineColor="text-coupa-blue"
                ctaSubTitle={ctaSubTitle}
                ctaCopy={ctaCopy}
              />
              <a
                className="bg-coupa-blue coupa-button hover:bg-coupa-blue-light"
                href={to}
                target="_blank"
                rel="noreferrer"
              >
                {ctaButton}
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          {cards.map(cardText => (
            <div
              className="flex w-full px-8 mb-4 md:w-1/3 md:px-4 md:mb-0"
              key={cardText.cardQuote}
            >
              <Card
                cardQuote={cardText.cardQuote}
                cardAuthorTitle={cardText.cardAuthorTitle}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)
