import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

function Image(props) {
  const { childImageSharp, image } = props.src

  if (image && image.childImageSharp) {
    return (
      <Img
        style={props.style}
        className={props.className}
        fluid={image.childImageSharp.fluid}
        alt={props.alt}
      />
    )
  }

  if (childImageSharp) {
    return (
      <Img
        style={props.style}
        className={props.className}
        fluid={childImageSharp.fluid}
        alt={props.alt}
      />
    )
  }

  if (image && typeof image === 'string') {
    return (
      <img
        style={props.style}
        className={props.className}
        src={image}
        alt={props.alt}
      />
    )
  }
  return null
}

Image.propTypes = {
  props: PropTypes.object,
  alt: PropTypes.string,
  childImageSharp: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  style: PropTypes.string,
  className: PropTypes.string
}

export default Image
