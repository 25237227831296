import React from "react"
import {nanoid} from 'nanoid'
import Image from "./Image"

const LogoGrid = props => (
  <div className="container px-2 mx-auto logo-grid md:px-0">
    {/* <div className="text-center grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-4"> */}
    <div className="flex flex-col md:flex-row flex-wrap justify-evenly">
      {props.gridItems.map((item) => (
        <div key={nanoid()} className="logoCard relative flex flex-col md:mx-4 lg:mx-0 p-4 mb-4 text-center md:w-64">
          <div>
            <div className="">
              <div style={{ maxWidth: "200px", margin: "0 auto" }}>
                <Image
                  src={item.image}
                  className="mx-auto" 
                  alt={item.alt}
                />
              </div>
            </div>
            <div className="flex-col flex-1 h-auto">
              <h3 className="text-2xl sm:text-xl md:text-sm lg:text-xl pb-6">
                {item.heading}
              </h3>
            </div>
            <div className="">
              <a
                href={item.href}
                target="_blank"
                rel="noreferrer"
                className="bg-coupa-blue absolute bottom-0 transform -translate-x-1/2 mb-2 coupa-button hover:bg-coupa-blue-light"
              >
                {item.button}
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default LogoGrid
